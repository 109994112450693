
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';


@Options({})
export default class Home extends Vue {
    @Prop({
        type: Number,
    })
    private readonly width!:number;
}
