
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import MasteryInfo from '@/components/mastery/info-card.vue';
import MasteryCalculation from '@/components/mastery/mastery-calculation.vue';
import cfg from '@/config/mastery.json';

const allType = ['trove','geode'];
@Options({
    components:{
        MasteryInfo,
        MasteryCalculation
    }
})
export default class extends Vue {
    @Prop()
    private readonly type!:string;
    handleSetType(type:string):void{
        this.$router.replace({
            name: 'mastery',
            params: {
                type,
            },
        });
    }
    troveMasteryPoint=147450;
    geodeMasteryPoint=13591;
    get sigils():string[]{
        const list:string[]=cfg.sigil.map((max, index) => {
            return `${cfg.sigil[index-1]||0}-${max-1}`;
        });
        list.push(`${cfg.sigil[cfg.sigil.length-1]}+`);
        return list;
    }
}
