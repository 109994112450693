
import { getLvByPoint, getMasteryLvPoint } from '@/utils/mastery';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ExpBar from './exp-bar.vue';

@Options({
    components:{
        ExpBar,
    },
})
export default class Home extends Vue {
    @Prop({
        type: String,
    })
    private readonly type!:string;
    private lType = 'trove';
    get dispalyType():string{
        return this.type || this.lType;
    }
    set dispalyType(value:string){
        if(!this.type){
            this.lType=value;
        }
        this.$emit('update:type',value);
    }
    @Prop({
        type: Number,
        default: 1,
    })
    public readonly troveMasteryPoint!:number;
    public get troveMasteryInfo():[number,number]{
        return getLvByPoint(this.troveMasteryPoint);
    }
    public get troveMasteryLv():number{
        return this.troveMasteryInfo[0];
    }
    public get troveMasteryLvPoint():number{
        return this.troveMasteryInfo[1];
    }
    public get troveMasteryPointToLvup():number{
        return getMasteryLvPoint(this.troveMasteryLv);
    }
    @Prop({
        type: Number,
        default: 1,
    })
    public readonly geodeMasteryPoint!:number;
    public get geodeMasteryInfo():[number,number]{
        return getLvByPoint(this.geodeMasteryPoint, 'geode');
    }
    public get geodeMasteryLv():number{
        return this.geodeMasteryInfo[0];
    }
    public get geodeMasteryLvPoint():number{
        return this.geodeMasteryInfo[1];
    }
    public get geodeMasteryPointToLvup():number{
        return getMasteryLvPoint(this.geodeMasteryLv, 'geode');
    }
    private get totalMasteryLv():number{
        return this.troveMasteryLv + this.geodeMasteryLv;
    }
    private get displayInfo():{
        lv: number;
        width: number;
    }{
        if(this.dispalyType==='geode'){
            return {
                lv:this.geodeMasteryLv,
                width:this.geodeMasteryPointToLvup === 0 ? 100 : this.geodeMasteryLvPoint/this.geodeMasteryPointToLvup*100,
            };
        }
        return {
            lv:this.troveMasteryLv,
            width:this.troveMasteryPointToLvup === 0 ? 100 : this.troveMasteryLvPoint/this.troveMasteryPointToLvup*100,
        };
    }
}
